import getCommandsModule from './getCommandsModule';
import toolbarModule from './toolbarModule';
import init from './init.js';

/**
 * Constants
 */

/**
 * Extension
 * Documentation extensions: docs/latest/extensions/index.md
 */
export default {
  /**
   * Only required property. Should be a unique value across all extensions.
   */
  id: 'dicom-sync-series',

  preRegistration({ servicesManager, configuration = {} }) {
    init(servicesManager, configuration);
  },

  /**
   * MODULE GETTERS
   */
  getCommandsModule({ servicesManager }) {
    return getCommandsModule(servicesManager);
  },
  getToolbarModule() {
    return toolbarModule;
  },
};
