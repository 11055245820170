const TOOLBAR_BUTTON_TYPES = {
  COMMAND: 'command',
  SET_TOOL_ACTIVE: 'setToolActive',
  BUILT_IN: 'builtIn',
};

const definitions = [
  // Toggles the action upon scrolling (mouseButton4)
  {
    id: 'SeriesScroll',
    label: 'Series Scroll',
    icon: 'link',
    //
    type: TOOLBAR_BUTTON_TYPES.COMMAND,     // highlight button
    commandName: 'enableSeriesScrollTool',  // update the effect of user actions
    commandOptions: {
      toolName: 'SeriesScroll',
      isActive: true,
    },
    options: {
      togglable: false,
    },
  },
];

export default {
  definitions,
  defaultContext: 'ACTIVE_VIEWPORT::CORNERSTONE',
};
