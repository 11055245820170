import cornerstoneTools, { StackScrollMouseWheelTool, getToolState, importInternal } from 'cornerstone-tools';

const scrollToIndex = importInternal('util/scrollToIndex');
const clip = importInternal('util/clip');
const throttle = importInternal('util/throttle');


export class SeriesScrollMouseWheelTool extends StackScrollMouseWheelTool {
  timerId = null;
  direction = 0;

  constructor(props = {}) {
    props.name = 'SeriesScrollMouseWheel';
    super(props);

    // Called once a leading, then on trailing
    this.mouseWheelCallback = this.mouseWheelCallback.bind(this);
    this.timerId = null;
    this.direction = 0;
  }

  /**
   * @param object mouseWheelData - see wheelEventHandler
   */
  mouseWheelCallback(mouseWheelData) {
    const { direction } = mouseWheelData.detail;
    this.direction += direction;

    setTimeout(() => {
      let n = this.direction;
      this.direction = 0;
      this.timerId = null;
      this.mouseWheelDebounced(n);
    }, 200);
  }

  mouseWheelDebounced(n) {

    // Loop viewports
    const elements = cornerstoneTools.store.state.enabledElements;

    elements.forEach((element) => {

      // Retrieve the stack of this viewport
      const toolData = getToolState(element, 'stack');
      const stackData = toolData && toolData.data && toolData.data.length ? toolData.data[0] : null;

      if (!stackData) {
        return;
      }

      // Scroll to minIndex < currentIndex+n < maxIndex
      const currentIndex = stackData.currentImageIdIndex;
      const maxIndex = stackData.imageIds.length - 1;
      const newIndex = clip(currentIndex + n, 0, maxIndex);

      if (currentIndex == newIndex) {
        return;
      }
      scrollToIndex(element, newIndex);
    });
  }
}

export default function init(servicesManager, configuration) {
  cornerstoneTools.addTool(SeriesScrollMouseWheelTool);

  cornerstoneTools.setToolActive('SeriesScrollMouseWheel', {});
}
