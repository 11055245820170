import { utils } from '@ohif/core';
import React from 'react';
import cornerstoneTools from 'cornerstone-tools';


export default function getCommandsModule(servicesManager) {
  const actions = {
    enableSeriesScrollTool(options) {
      const isActive = !options.isActive;
      const currentButton = options.button;
      if (!currentButton) {
        return;
      }

      // Toggle state in button.commandOptions
      options.button.commandOptions.isActive = isActive;

      if (isActive) {
        // Set action upon scrolling to synchronized scroll
        cornerstoneTools.setToolActive('SeriesScrollMouseWheel', {
          mouseButtonMask: 4,
        });
        // Add active state in toolbar
        options.parent && options.parent.setState((state) => ({
          activeButtons: [...state.activeButtons, currentButton]
        }));

      } else {
        // Set action upon scrolling to active viewport scroll
        cornerstoneTools.setToolActive('StackScrollMouseWheel', {
          mouseButtonMask: 4,
        });
        // Remove active state in toolbar
        options.parent && options.parent.setState((state) => ({
          activeButtons: state.activeButtons.filter((button) => button.id != currentButton.id)
        }));
      }
    },
  };

  const definitions = {
    enableSeriesScrollTool: {
      commandFn: actions.enableSeriesScrollTool,
      storeContexts: [], // Array of string of modules required from store
                         // [ 'extensions', 'preferences', 'servers', 'studies', 'timepointManager', 'viewports', 'oidc' ]
    },
  };

  return {
    actions,
    definitions,
  };
}
